<template>
  <div class="view pa24">
    <div class="d-flex">
      <div>
        <el-input
            style="width: 200px!important;"
            class="w120 mb10 mr10"
            placeholder="产品名称"
            v-model="filterName"
            clearable
        />
        <el-button type="primary" @click="filterNames">搜索</el-button>
      </div>
      <div style="margin-left: auto">
        <!-- <el-button class="ma mr10 mb10" @click="delAll">批量删除</el-button> -->
        <el-button class="ma" type="primary" @click="centerDialogVisible = true,upDate=1"
        >+ 新增
        </el-button
        >
      </div>
    </div>
    <commonTable
        :tableData="tableData"
        :total="total"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
        :currentPage="currentPage"
        :loading="loading"
    >
      <template v-slot:table>
        <el-table-column type="selection"/>
        <el-table-column prop="couldName" align="center" label="产品名称"/>
        <el-table-column align="center" label="缩略图">
          <template slot-scope="scope">
            <el-image style="width: 80px; height: 40px" :src="scope.row.logo"
                      :preview-src-list="srcList">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column align="center" label="排序">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="数值越大，排序越靠前" placement="right-start">
              <el-input v-model="scope.row.sort" min="0"
                        @input="scope.row.sort=scope.row.sort.replace(/^0(0+)|[^\d]+/g,'')" placeholder="数值越大，排序越靠前"
                        @change="saveSort(scope.row)"/>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" align="center" label="创建时间"/>
        <el-table-column align="center" label="操作" width="300px">
          <template slot-scope="scope">
            <el-button type="primary" plain @click="open(scope.row)"
            >编辑
            </el-button
            >
            <customPopconfirm
                class="ma ml10 mb10"
                confirm-button-text="确定"
                cancel-button-text="取消"
                @confirm="del(scope.row.couldId)"
                title="确认删除？">
              <el-button type="danger" slot="reference" plain>删除</el-button>
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
        title="新增"
        :visible.sync="centerDialogVisible"
        :modal-append-to-body="false"
        width="50%"
        center
        @close="close"
    >
      <el-form
          ref="ruleForm" :model="ruleForm" label-width="110px" :rules="rules" class="view pa24" label-position="left"
      >
        <el-form-item prop="projectTitle" label="产品名称">
          <el-input
              clearable
              v-model="ruleForm.projectTitle"
              style="width: 100%"
              placeholder="请输入产品名称"
              maxlength="50"
              show-word-limit
          ></el-input>
        </el-form-item>

        <el-form-item prop="sort" label="排序">
          <el-input
              clearable
              v-model="ruleForm.sort"
              style="width: 100%"
              placeholder="请输入排序"
              maxlength="50"
              show-word-limit
          ></el-input>
        </el-form-item>

        <el-form-item prop="coverImg" label="缩略图">
          <p class="ma mb10" style="color: rgb(150, 150, 150)">
            图片上传比例为1:1，支持格式png、jpg、jpeg，大小不超过1M
          </p>
          <div>
            <el-upload
                :action="uploadUrl"
                :class="{disabled: uploadDisabled2}"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove2"
                :before-upload="beforeAvatarUploadImg"
                :on-success="shareUpload"
                :file-list="ruleForm.coverImg"
                :limit="1"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog
                :visible.sync="dialogVisible"
                append-to-body
                :modal-append-to-body="false"
            >
              <img width="100%" :src="dialogImageUrl" alt=""/>
            </el-dialog>
          </div>
        </el-form-item>

        <el-form-item prop="goodPhoto" label="产品图">
          <p class="ma mb10" style="color: rgb(150, 150, 150)">
            图片上传比例为2:1，支持格式png、jpg、jpeg，大小不超过1M
          </p>
          <div style="display: flex">
            <el-upload
                :action="uploadUrl"
                :class="{
              disabled: uploadDisabled,
            }"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :on-success="goodsUpload"
                :before-upload="beforeAvatarUploadImg"
                :file-list="ruleForm.goodPhoto"
                :limit="15"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog
                :visible.sync="dialogVisible"
                append-to-body
                :modal-append-to-body="false"
            >
              <img width="100%" :src="dialogImageUrl" alt=""/>
            </el-dialog>
          </div>
        </el-form-item>

        <el-form-item label="产品介绍" prop="projectDetails">
          <!-- //富文本 -->
          <quill-editor
              class="ql-editor"
              style="height: 100%"
              @ready="ready($event)"
              v-model="ruleForm.projectDetails"
              ref="myQuillEditor"
              :options="editorOption"
              @change="onEditorChange($event)"
          />
          <span class="wordNumber">{{ TiLength }}/10000</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {lineHeightStyle} from "@/utils/lineHeight.js";
import commonTable from "@/components/common/commonTable";
import customPopconfirm from "@/components/common/customPopconfirm";
import {quillEditor, Quill} from "vue-quill-editor"; //
import resizeImage from "quill-image-resize-module"; // 调整大小组件。
import {ImageDrop} from "quill-image-drop-module"; // 拖动加载图片组件
import quillConfig from "@/utils/quill-config.js";

Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/resizeImage ", resizeImage);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

quillConfig.placeholder = '请填写内容'

import {addCouldDisplayData,queryCouldDisplayDataPage,updaateCouldDisplayData,delCouldDisplayData} from "@/api/cloudHall";

export default {
  name: "productDisplay",
  components: {
    commonTable,
    customPopconfirm
  },
  props: {
    jurisdiction: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputMaxL: 10,
      TiLength: 0,
      editorOption: quillConfig,
      centerDialogVisible: false,
      donationJL: false,
      ruleForm: {
        projectTitle: "",
        sort: '',
        phone: '',
        projectDetails: "",
        coverImg: [],
        goodPhoto: [],
      },
      dialogVisible: false,
      rules: {
        projectTitle: [{required: true, message: "请输入企业名称", trigger: "blur"}],
        projectDetails: [{required: true, message: "请输入企业介绍", trigger: "blur"}],
        sort: [{required: true, message: "请输入排序", trigger: "blur"}],
        coverImg: [{required: true, message: "请上传缩略图"}],
        goodPhoto: [{required: true, message: "请上传产品图"}],
      },
      userInfo: this.$store.state.loginRoot.userInfo,
      tableData: [],
      filterName: "",
      row: "",
      delGoodsT: [],
      currentPage: 1,
      loading: false,
      loading1: false,
      total: 0, //总条数
      pageNum: 1,
      pageSize: 10,
      uploadUrl: this.$store.state.uploadingUrl,
      dialogImageUrl: "",
      upDate: 1,
      couldId: '',
      donationList: [],
      currentPage1: 1,
      total1: 0, //总条数
      pageNum1: 1,
      pageSize1: 10,
      srcList: [],
      multipleSelection: [],
      isUpdate: true,
    };
  },
  computed: {
    uploadDisabled() {
      return this.ruleForm.goodPhoto.length >= 115;
    },
    uploadDisabled2() {
      return this.ruleForm.coverImg.length !== 0;
    },
  },
  mounted() {
    this.queryPage();
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    ready() {
      Quill.register({'formats/lineHeight': lineHeightStyle}, true);
    },
    onEditorChange(e) {
      // e.quill.deleteText(4000,0);
      if (this.ruleForm.details == '') {
        this.TiLength = 0
      } else {
        this.TiLength = e.quill.getLength() - 1
      }
    },
    async queryPage(payload) {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        associationId: localStorage.getItem("associationId"),
        dataFrom:3,
        ...payload
      };
      try {
        this.loading = true;
        const result = await queryCouldDisplayDataPage(data);
        this.loading = false;
        const {total, list} = result.data.pageInfo;
        let a = []
        for (let i in list) {
          list[i].createTime = this.renderTime(list[i].createTime)
          a.push(list[i].logo)
        }
        this.srcList = a
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.tableData = []
        this.loading = false;
      }
    },
    renderTime(date) {
      var dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
    //搜索
    async filterNames() {
      this.currentPage = 1;
      let data = {
        couldName: this.filterName
      };
      this.queryPage(data);
    },
    //当前页变更
    handleCurrentChange(val) {
      let data = {
        couldName: this.filterName
      };
      this.currentPage = val;
      this.queryPage(data);
    },
    //当前页查询数据条数变更
    handleSizeChange(val) {
      let data = {
        couldName: this.filterName
      };
      this.pageSize = val;
      this.queryPage(data);
    },
    async saveSort(row) {
      if (row && row.sort != "") {
        let ajax = {
          sort: row.sort,
          couldId: row.couldId
        }
        const result = await updaateCouldDisplayData(ajax);
        this.$message({
          type: "info",
          message: "保存成功",
        });
        this.queryPage();
      } else {
        this.$message({
          type: "error",
          message: "排序不能为空",
        });
      }
    },
    determine() {
      if (this.ruleForm.goodPhoto.length == 0) {
        return this.$message({
          message: "产品图未上传",
          type: "warning",
          center: true,
        });
      }
      if (this.ruleForm.coverImg.length == 0) {
        return this.$message({
          message: "缩略图未上传",
          type: "warning",
          center: true,
        });
      }
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            let arr = this.ruleForm.goodPhoto.map((item) => item.url);
            let data = {
              associationId: localStorage.getItem("associationId"),
              couldName: this.ruleForm.projectTitle,
              content: this.ruleForm.projectDetails,
              logo: this.ruleForm.coverImg && this.ruleForm.coverImg.length != 0
                  ? this.ruleForm.coverImg[0].url
                  : [],
              topImg: arr.join(","), //顶部
              sort: this.ruleForm.sort,
              isDel: 0,
              dataFrom:3
            };
            this.$message({
              message: "正在保存",
            });
            if (this.upDate == 1) {
              await addCouldDisplayData(data);
            } else {
              data.couldId = this.couldId
              await updaateCouldDisplayData(data);
            }
            this.centerDialogVisible = false;
            this.$message({
              message: "保存成功",
              type: "success",
            });
            await this.queryPage();
          } catch (error) {
            this.$message.closeAll();
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /**@method 删除
     * @param {String} val - 文章id
     */
    async del(val) {
      try {
        await delCouldDisplayData({couldId: val});
        await this.queryPage();
      } catch (error) {
        console.log(error);
      }
    },
    /**@method 编辑
     * @param {Object} val - 当前点击行的值
     */
    async open(val) {
      this.upDate = 2
      this.centerDialogVisible = true;
      let data = JSON.parse(JSON.stringify(val));
      if (data.topImg) {
        this.ruleForm.goodPhoto = data.topImg.split(",").map((item, index) => {
          return {
            url: item,
            uid: index,
          };
        });
      }
      if (data.logo && data.logo.length !== 2 && data.logo.length !== 0) {
        this.ruleForm.coverImg = [{url: data.logo, uid: 1}];
      } else {
        this.ruleForm.coverImg = [];
      }
      this.ruleForm.projectTitle = data.couldName;
      this.ruleForm.sort = data.sort;
      this.ruleForm.projectDetails = data.content;
      this.couldId = data.couldId
    },

    //图片
    shareUpload(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      let arr = [];
      fileList = fileList.map((item) => {
        if (item.response) {
          arr.push({
            url: `https://jkt-one-stand.obs.cn-southwest-2.myhuaweicloud.com/${item.response.data}`,
            uid: item.uid,
          });
        } else {
          arr.push(item);
        }
      });
      this.ruleForm.coverImg = arr;
    },
    beforeAvatarUploadImg(file) {
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      }
      return isLt2M;
    },
    handleRemove(file, fileList) {
      this.ruleForm.goodPhoto = fileList;
    },
    handleRemove2(file, fileList) {
      this.ruleForm.coverImg = fileList;
    },
    //商品图片
    goodsUpload(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      console.log(fileList)
      let arr = [];
      fileList = fileList.map((item) => {
        if (item.response) {
          arr.push({
            url: `https://jkt-one-stand.obs.cn-southwest-2.myhuaweicloud.com/${item.response.data}`,
            uid: item.uid,
          });
        } else {
          arr.push(item);
        }
      });

      this.ruleForm.goodPhoto = arr;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    /**@method 模态框关闭的回调 */
    close() {
      (this.ruleForm = {
        projectTitle: "",
        projectDetails: "",
        coverImg: [],
        goodPhoto: [],
        sort: ""
      }),
          this.multipleSelection = []
      this.centerDialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  line-height: 180px;
  text-align: center;
}

/deep/ .el-upload--picture-card .el-upload--text {
  width: 148px;
  height: 148px;
}

/deep/ .el-upload--picture-card .avatar-uploader-icon {
  line-height: 148px;
}

/deep/ .avatar-coverImg .el-upload--text {
  width: 100px;
  height: 100px;
}

/deep/ .avatar-coverImg .avatar-uploader-icon {
  line-height: 100px;
}

.select-goods-list {
  margin-bottom: 20px;
  border-radius: 40px;
  box-sizing: border-box;
  padding: 0 20px;
  background: #ffffff;
  transition: all 0.4s;
}

.select-goods-list:hover {
  background: #f5f5f6;
  transition: all 0.4s;
  cursor: pointer;
}

/deep/ .disabled .el-upload--picture-card {
  display: none;
}
</style>




